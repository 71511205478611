import {ReactElement, useState} from "react";
import {Configure, InstantSearch} from "react-instantsearch-dom";
import {HeaderSearchSearchbox} from "./HeaderSearchSearchbox";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {HeaderSearchResults} from "./HeaderSearchResults";
import {HeaderSearchResultsButton} from "./HeaderSearchResultsButton";
import {Transition} from "@headlessui/react";
import qs from "qs";
import {initAlgolia} from "lib/algolia/algoliaClient";
import {InsightsClient} from "react-instantsearch-core";
import {SearchState} from "components/advanced-search/AdvancedSearch";
import {pick} from "ramda";
import {qsFormat} from "lib/urlParsing";
import {FormattedMessage} from "react-intl";
import {ReactComponent as FavoritesIconGold} from "../../widgets/favorite-icons/favorites-icon-gold.svg";
import {redirectTo} from "../../lib/utility";

function searchStateToUrl(searchState: SearchState | null): string {
	const queryString = qs.stringify(
		pick(['query', 'refinementList'], searchState),
		qsFormat.stringify
	);
	return `/search/?${queryString}`;
}

export interface HeaderSearchProps {
	appId: string,
	apiKey: string,
	productIndex: string,
	isAnonymous?: boolean
}

export function HeaderSearch(props: HeaderSearchProps): ReactElement {
	// algolia
	const algolia = initAlgolia(props.appId, props.apiKey);
	const [searchState, setSearchState] = useState<any>({});
	const searchTerm = searchState.query ?? "";

	// all the component logic
	const isMobile = !useMediaQuery(mediaQueries.sm);
	const [isSearchIconClicked, setIsSearchIconClicked] = useState(false);

	const showSearchBox = !isMobile || isSearchIconClicked;
	const showResults = showSearchBox && searchTerm.length > 0;
	const showSearch = showSearchBox || showResults;
	const showSearchIcon = isMobile && !isSearchIconClicked;

	const hitsPerPage = isMobile ? 6 : 15;

	const goToSearch = () => window.location.href = searchStateToUrl(searchState);

	return <div className="tw-hidden tw-w-full lg:tw-block md:tw-block">
		<InstantSearch
			searchClient={algolia.client}
			indexName={props.productIndex}
			searchState={searchState}
			onSearchStateChange={setSearchState}
			createURL={searchStateToUrl}
		>
			<Configure
				typoTolerance={true}
				page={0}
				hitsPerPage={hitsPerPage}
				facets={['categories', 'manufacturer']}
				analytics={true}
				clickAnalytics={true}
			/>

			{showSearchIcon &&
				<div>
					<div className={"tw-flex tw-flex-row tw-mt-2 lg:tw-hidden"}>
						<div
							onClick={() => props.isAnonymous ? redirectTo("/favorites") : redirectTo("/my-articles/favorites")}>
							<FavoritesIconGold/>
						</div>

					</div>
					<div className="tw-text-right tw-w-full">
						<button
							className="tw-text-grey tw-text-4xl tw-hidden"
							onClick={() => setIsSearchIconClicked(true)}>
							<i className="fa fa-search"> </i>
						</button>
						<input className={"tw-bg-black"}/>
					</div>
				</div>
			}

			<Transition
				show={showSearch}
				unmount={false}
				enter="tw-transition tw-duration-100"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition tw-delay-100 tw-duration-200"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
			>
				<div
					className="tw-fixed tw-inset-0 tw-bg-white tw-z-10 tw-flex tw-flex-col lg:tw-static md:tw-static">

					{/* search textbox + cancel*/}
					<Transition
						className="tw-flex tw-flex-none tw-flex-row tw-shadow tw-py-3 tw-px-5 lg:tw-shadow-none md:tw-shadow-none"
						show={showSearchBox}
						unmount={false}
						enter="tw-transition-all tw-duration-300 tw-transform tw-transform-gpu lg:tw-transition-none"
						enterFrom="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						enterTo="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leave="tw-transition-all tw-duration-100 tw-transform tw-transform-gpu"
						leaveFrom="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leaveTo="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						id="search-input"
					>
						<div className="tw-flex-1 tw-px-1">
							<HeaderSearchSearchbox onSubmit={goToSearch}/>
						</div>
						<div className="tw-flex-none tw-text-right tw-px-2 tw-py-3 lg:tw-hidden md:tw-hidden">
							<button onClick={() => setIsSearchIconClicked(false)}>
								<FormattedMessage id="frontend.header-search.mobile.popup.cancel"/>
							</button>
						</div>
					</Transition>

					{/* dropdown */}
					<Transition
						className="tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden lg:tw-relative lg:tw-overflow-visible tw-bg-white tw-z-50"
						show={showResults}
						unmount={true}
						enter="tw-transition-all tw-duration-300 tw-transform"
						enterFrom="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						enterTo="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leave="tw-transition-all tw-duration-200 tw-transform"
						leaveFrom="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leaveTo="tw-opacity-0 tw-scale-95 tw--translate-y-8"
					>
						<div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-overflow-x-hidden
                                        lg:tw-block lg:tw-absolute lg:tw-top-0 lg:tw--left-24 lg:tw-max-h-searchBox lg:tw-w-searchBox
                                        lg:tw-flex lg:tw-flex-col lg:tw-overflow-hidden
                                        lg:tw-bg-white lg:tw-shadow-drop-down">
							<div className="tw-flex-1 tw-overflow-y-auto tw-overflow-x-hidden tw-relative">
								<HeaderSearchResults
									productIndex={props.productIndex}
									insightsClient={algolia.searchInsights as InsightsClient}
									searchTerm={searchTerm}
								/>
							</div>

							{/* go to search button */}
							<div className="tw-flex-none tw-shadow-top-white">
								<HeaderSearchResultsButton
									searchTerm={searchTerm}
									onClick={goToSearch}
								/>
							</div>
						</div>
					</Transition>
				</div>
			</Transition>
		</InstantSearch>
	</div>
}
