import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {HeaderSearch} from "components/header-search/HeaderSearch";
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";

const target = document.getElementById('header-search');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const appId = target?.dataset?.['appId']!;
const apiKey = target?.dataset?.['apiKey']!;
const productIndex = target?.dataset?.['productIndex']!;
const isAnonymous = target?.dataset?.['isAnonymous'];

ReactDOM.render(
	<BundleEntry
		bundleName="header-search"
		locale={localeCode}
		prefetchTranslations={[
			'frontend.header-search.mobile.popup.cancel',
			'frontend.header-search.results-button.noHits',
			'frontend.header-search.results-button.noTerm',
			'frontend.header-search.results-button.showResults',
			'frontend.header-search.mobile.popup.cancel'
		]}>
		<HeaderSearch
			appId={appId}
			apiKey={apiKey}
			productIndex={productIndex}
			isAnonymous={isAnonymous === 'true'}
		/>
	</BundleEntry>,
	target
);

